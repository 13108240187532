import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TrashIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import Dropdown from "./Dropdown";
import { useSelector } from "react-redux";
import { paymentMethods  } from "../utils/constants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxWidth: "calc(100% - 30px)",
  bgcolor: "background.paper",
  border: "1px solid #ccc",
  p: 4,
  pt: 2,
  pb: 2,
  borderRadius: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: 24,
};

const PaymentRow = ({
  index,
  payment_method = "",
  amount = 0,
  setPaymentMethod = () => {},
  setAmount = () => {},
  currency = "INR",
  removePayment = () => {},
  disabled = false,
  canEditClosed = false,
  id,
}) => {
  return (
    <Grid
      container
      key={`${index}_payment_container`}
      spacing={2}
      sx={{ justifyContent: "center", mt: 1 }}
    >
      <Grid item key={`${index}_method`} xs={12} sm={4}>
        <Dropdown
          disabled={disabled}
          name={`payment_method`}
          value={payment_method}
          options={paymentMethods}
          handleChange={(e) => setPaymentMethod(index, e.target.value)}
          label="Payment Method"
        />
      </Grid>
      <Grid item key={`${index}_amount`} xs={12} sm={4}>
        <TextField
          name={`amount`}
          value={parseFloat(amount, 0).toFixed(2)}
          disabled={disabled}
          variant="standard"
          type="text"
          onChange={(e) => {
            const newValue = e.target.value;
              if (newValue.includes('-')) {
                return;
              } else if (newValue === '') {
                setAmount(index, 0);
              } else {
                setAmount(index, newValue);
              }
          }}
          label="Amount"
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{currency}</InputAdornment>
            ),
            endAdornment:
              (index > 0 && !disabled) || (canEditClosed && id) ? (
                <InputAdornment position="end">
                  <IconButton onClick={() => removePayment(index, id)}>
                    <TrashIcon />
                  </IconButton>
                </InputAdornment>
              ) : (
                <></>
              ),
          }}
        />
      </Grid>
    </Grid>
  );
};

const PartialPaymentWaring = ({ open, setWarning, addPayments }) => {
  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography variant="h6">Warning</Typography>
        <Typography variant="" sx={{ p: 2 }}>
          Amount entered is less than total amount payable. Proceed and mark
          this as partially paid?
        </Typography>
        <Grid container justifyContent={"center"}>
          <Button
            onClick={() => {
              setWarning(false);
              addPayments();
            }}
            sx={{ m: 1 }}
          >
            Proceed
          </Button>
          <Button onClick={() => setWarning(false)} sx={{ m: 1 }}>
            Cancel
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export const PaymentWindow = ({
  open,
  handleClose,
  currency,
  amount = 0,
  paymentsData = [],
  tip = 0,
  updateTip = () => {},
  addPayments,
  deletePayment = () => {},
  setPay = () => {},
  noTip = false,
  status = "",
  outstanding_balance = 0,
}) => {
  const disabled = status !== "PENDING";
  const canEditClosed = useSelector(state => state.auth.user.can_edit_closed_apt);
  const [disableSave, setDisableSave] = useState(false);
  const [editTip, setEditTip] = useState(false);
  const [_tip, _setTip] = useState(tip);
  // if (!paymentsData.length)
  //   paymentsData = [{ payment_method: "", amount: amount + _tip }];
  const [payments, setPayments] = useState([]);
  const [total, setTotal] = useState(0);
  const [currTotal, setCurrTotal] = useState(0);
  const [tap, setTap] = useState(0);
  const [warning, setWarning] = useState(false);
  const [paid, setPaid] = useState(0);
  const [saving, setSaving] = useState(false);
  const pending =
    tap - payments.reduce((a, b) => parseFloat(a) + parseFloat(b.amount), 0);
  const _setPaymentMethod = (i, v) => {
    let _payments = payments;
    _payments[i].payment_method = v;
    setPayments([..._payments]);
  };
  const _setAmount = (i, v) => {
    let _payments = payments;
    _payments[i].amount = v;
    setPayments([..._payments]);
  };
  const addPayment = () => {
    setPayments([...payments, { payment_method: "", amount: pending }]);
  };
  const removePayment = (i, id) => {
    const cb = () => {
      let _payments = payments;
      _payments.splice(i, 1);
      setPayments([..._payments]);
    }
    if (id){
      deletePayment(id, cb);
    } else {
      cb();
    }
  };
  const setTip = (v) => {
    v = v.replace("-", "");
    _setTip(parseFloat(v));
  }
  useEffect(() => {
    setTotal(payments.reduce((a, b) => a + parseFloat(b.amount), 0));
  }, [payments]);
  const handleSave = () => {
    setSaving(true);
    if (total < tap) {
      setWarning(true);
    } else {
      addPayments(payments, false, () => {
        setPay(false);
        setSaving(false);
      });
    }
  };
  useEffect(() => {
    setTap(
      parseFloat(amount) + parseFloat(tip) + parseFloat(outstanding_balance)
    );
  }, [amount, tip, outstanding_balance]);

  useEffect(() => {
    let d = payments.every((p) => p.id);
    if (!d) d = payments.some((p) => !p.payment_method);
    setDisableSave(d);
  }, [payments]);

  useEffect(() => {
    let _t = 0;
    let _curTotal = 0;
    payments.forEach((p) => {
      if (p.id) {
        _t += parseFloat(p.amount);
      } else {
        _curTotal += parseFloat(p.amount);
      }
    });
    setPaid(_t);
    setCurrTotal(_curTotal);
  }, [payments]);

  useEffect(() => {
    if (paymentsData.length) setPayments([...paymentsData]);
  }, [paymentsData]);


  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography variant="h6" sx={{ p: 2 }}>
          Payment
        </Typography>

        <TextField
          sx={{ width: "320px", mb: 2 }}
          disabled={true}
          variant="standard"
          value={parseFloat(amount).toFixed(2)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                Total Bill: {currency}
              </InputAdornment>
            ),
          }}
        />
        {!noTip ? (
          <TextField
            sx={{ width: "320px", mb: 2 }}
            disabled={!editTip}
            variant="standard"
            value={_tip}
            type="number"
            onChange={(e) => setTip(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  Tip: {currency}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {!disabled ? (
                    <IconButton>
                      {editTip ? (
                        <SaveIcon
                          onClick={() => {
                            setEditTip(false);
                            updateTip(_tip);
                          }}
                        />
                      ) : (
                        <EditIcon onClick={() => setEditTip(true)} />
                      )}
                    </IconButton>
                  ) : (
                    ""
                  )}
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <></>
        )}
        {outstanding_balance ? (
          <TextField
            sx={{ width: "320px", mb: 2 }}
            disabled={true}
            variant="standard"
            value={outstanding_balance}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  Outstanding Balance: {currency}
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <></>
        )}
        <TextField
          sx={{ width: "320px", mb: 2 }}
          disabled={true}
          variant="standard"
          value={status === "PAID" ? 0 : parseFloat(tap - paid, 0).toFixed(2)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                Pending Amount Payable: {currency}
              </InputAdornment>
            ),
          }}
        />
        {payments?.map((p, i) => (
          p.is_active !== false) && <PaymentRow
          disabled={p.id ? true : false}
          index={i}
          currency={currency}
          payment_method={p.payment_method}
          amount={p.amount}
          setAmount={(i, v) => _setAmount(i, v)}
          setPaymentMethod={(i, v) => _setPaymentMethod(i, v)}
          removePayment={(i, id) => removePayment(i, id)}
          canEditClosed={canEditClosed}
          id={p.id}
          />)
        }
        {!disabled || status !== "PAID" ? (
          <Grid container justifyContent={"flex-end"}>
            <Button
              size="small"
              sx={{ fontSize: "11px", float: "right" }}
              onClick={addPayment}
            >
              Add Payment
            </Button>
          </Grid>
        ) : (
          <></>
        )}
        {status !== "PAID" && currTotal > tap - paid && (
          <Grid container spacing={2} justifyContent={"center"} pt={2}>
            <Typography color="red">
              Sum of individual payments exceeds total amount payable.
            </Typography>
          </Grid>
        )}
        <Grid container spacing={2} justifyContent={"center"} pt={2}>
          <Grid item>
            <Button
              disabled={saving || disableSave || total > tap || !payments.length}
              onClick={() => handleSave()}
            >
              {saving ? 'Saving...' : 'Save'}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={handleClose}>Back</Button>
          </Grid>
        </Grid>
        <PartialPaymentWaring
          open={warning}
          setWarning={setWarning}
          addPayments={() => addPayments(payments, true, () => {
            setPay(false);
            setSaving(true);
          })}
        />
      </Box>
    </Modal>
  );
};

export default PaymentWindow;
